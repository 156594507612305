<template>
  <div class="job-container">
    <div class="g-section">
      <div class="g-wrap mt-20 p-20">
        <el-cascader
          class="mr-10"
          v-model="searchArea"
          :options="optionsArea"
          placeholder="地区"
        ></el-cascader>
        <el-cascader
          class="mr-10"
          v-model="searchArea"
          :options="optionsArea"
          placeholder="行业"
        ></el-cascader>
        <!-- <el-select v-model="value" class="mr-10" placeholder="规模">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <el-input
          placeholder="请输入关键字搜索"
          v-model="query.KeyWord"
          class="mr-10 input-with-select"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </div>
    </div>
    <!-- <div class="g-section mt-20 mb-20">
      <div class="g-wrap flex-column p-20">
        <div class="sel-condition">
          <div>
            <span class="sel-condition-title">已选条件:</span>
            <div class="sel-condition-wrap ">
            </div>
          </div>
          <span>共找到<span class="job-title">888+</span>家公司</span>
        </div>

      </div>
    </div> -->
    <div class="g-section">
      <div class="g-wrap flex-column mb-20 pb-20">
        <div class="enterprise-list">
          <div
            class="enterprise-item"
            v-for="(item, i) in listEnterprise"
            :key="i"
          >
            <img :src="item.Logo" class="item-logo" />
            <span class="item-name">{{ item.Name }}</span>
            <span>软件/服务|99人</span>
            <span>热招岗位2条</span>
          </div>
        </div>
        <el-pagination background layout="prev, pager, next" :total="1000">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import { getPageList } from '@/api/enterprise'
export default {
  data() {
    return {
      searchZone: "1",
      searchKeyword: "",
      searchArea: [],
      optionsArea: [
        {
          value: "gd",
          label: "广东",
          children: [
            {
              value: "sz",
              label: "深圳",
              children: [
                {
                  value: "lh",
                  label: "龙华"
                },
                {
                  value: "ns",
                  label: "南山"
                },
                {
                  value: "ft",
                  label: "福田"
                },
                {
                  value: "lg",
                  label: "龙岗"
                }
              ]
            },
            {
              value: "gz",
              label: "广州",
              children: [
                {
                  value: "by",
                  label: "白云"
                },
                {
                  value: "th",
                  label: "天河"
                }
              ]
            }
          ]
        },
        {
          value: "hn",
          label: "湖南",
          children: [
            {
              value: "ch",
              label: "长沙",
              children: [
                {
                  value: "qb",
                  label: "全部"
                }
              ]
            }
          ]
        }
      ],
      optionsIndustry: [],
      listEnterprise: [
        { Id: "1", Logo: "", Name: "企业1" },
        { Id: "2", Logo: "", Name: "企业2" },
        { Id: "3", Logo: "", Name: "企业3" },
        { Id: "4", Logo: "", Name: "企业4" },
        { Id: "5", Logo: "", Name: "企业5" },
        { Id: "6", Logo: "", Name: "企业6" },
        { Id: "7", Logo: "", Name: "企业7" },
        { Id: "8", Logo: "", Name: "企业8" }
      ],
      listJob: [
        {
          Id: "1",
          Logo: "",
          Name: "前端开发",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "6-8K",
          Experience: "3-5年",
          UpdatedTime: "2020-12-30",
          IsAdd: true
        },
        {
          Id: "2",
          Logo: "",
          Name: "销售助理",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "6-8K",
          Experience: "3-5年",
          UpdatedTime: "2020-12-30"
        },
        {
          Id: "3",
          Logo: "",
          Name: "市场推广",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "6-8K",
          Experience: "1-3年",
          UpdatedTime: "2020-12-30"
        },
        {
          Id: "4",
          Logo: "",
          Name: "架构师",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "25K以上",
          Experience: "5-10年",
          UpdatedTime: "2020-12-30",
          IsAdd: true
        },
        {
          Id: "5",
          Logo: "",
          Name: "系统分析师",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "15K",
          Experience: "3-5年",
          UpdatedTime: "2020-12-30"
        },
        {
          Id: "6",
          Logo: "",
          Name: "岗位1",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "6-8K",
          Experience: "3-5年",
          UpdatedTime: "2020-12-30"
        },
        {
          Id: "7",
          Logo: "",
          Name: "岗位3",
          Enterprise: "智邻科技",
          Area: "深圳",
          Education: "本科",
          Salary: "6-8K",
          Experience: "3-5年",
          UpdatedTime: "2020-12-30",
          IsAdd: true
        }
      ],
      isExpand: false,
      query: {
        KeyWord: "",
        PageSize: 12,
        PaseIndex: 1
      }
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      // getPageList(this.query).then(res => {
      //   this.listEnterprise = res.Result.Data
      // }).catch((e) => { })
    }
  }
};
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  flex: 1;
}
.job-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.g-section {
  background-color: #f6f6f8;
}
.g-wrap {
  background-color: #ffffff;
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.enterprise-list {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  padding-left: 20px;
  box-sizing: border-box;
}
.enterprise-item {
  display: flex;
  flex-direction: column;
  width: 245px;
  height: 200px;
  justify-content: center;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #409ffe;
  cursor: pointer;
  margin-right: 20px;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
  .item-logo {
    height: 100px;
    width: 100px;
    margin-top: 10px;
  }
  .item-name {
    font-weight: 600;
  }
}
</style>
